import React, { useEffect, useState } from "react";
import Layout from "../components/shared/layout";
import PaymentStatus from "../components/shared/payment-status";
import getPaymentErrorMsg from '../service/payment-error-msg';
import orderStore from "../store/orderStore";
import deviceStore from "../store/deviceStore";
const OrderFailPage = () => {
  const [errorMsg2, setErrorMsg2] = useState('');
  useEffect(() => {
    setErrorMsg2(getPaymentErrorMsg(orderStore.paymentErrorCode));
  }, []);

  return (
    <Layout header={true} footer={true} isBackBtn={true}>
      <PaymentStatus message1={deviceStore.deviceImei === "NA" ? "payment.failed.deferred.message1" : "payment.failed.message1"} message2={deviceStore.deviceImei === "NA" ? "" : errorMsg2} message3={deviceStore.deviceImei === "NA" ? "" : orderStore.paymentErrorMessage} error={true} />
    </Layout>
  )
}

export default OrderFailPage;
