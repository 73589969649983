const getPaymentErrorMsg = (errorCode) => {
    let errorMsg = '';
    switch (errorCode) {
        case 3:
            return errorMsg = 'payment.failed.ageRestriction';
        case 16:
        case 37:
            return errorMsg = 'payment.failed.insufficientCredit';
        case 10:
            return errorMsg = 'payment.failed.transactionLimit';
        case 0:
        case 1:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
        case 29:
        case 30:
        case 31:
        case 32:
        case 33:
        case 34:
        case 35:
        case 36:
            return errorMsg = 'payment.failed.somethingWentWrong';
    }
}

export default getPaymentErrorMsg;