import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist'

class DeviceStore {
    deviceImei = '';
    deviceModel = '';
    deviceMake = '';
    deviceRrp = null;
    deviceError = null;
    deviceActivationDate = '';
    dateOfPurchase = null;
    skuCode = '';
    msisdn = '';
    kbxCode = '';
    updateDeviceDetail = (deviceDetail) => {
        this.deviceImei = deviceDetail.deviceImei;
        this.deviceMake = deviceDetail.deviceMake;
        this.deviceModel = deviceDetail.deviceModel;
        this.deviceRrp = deviceDetail.deviceRrp
        this.msisdn = deviceDetail.msisdn;
        this.deviceActivationDate = deviceDetail.deviceActivationDate;
        this.kbxCode = deviceDetail.kbxCode;
        this.skuCode = deviceDetail.skuCode
    }

    updateDeviceInfo = (deviceModel, deviceRrp, deviceMake) => {
        this.deviceModel = deviceModel;
        this.deviceRrp = deviceRrp;
        this.deviceMake = deviceMake;
    }

    updateDeviceActivationDate = (activationDate) => {
        this.deviceActivationDate = activationDate;
    }

    updateDeviceIMEI = (deviceIMEI) => {
        this.deviceImei = deviceIMEI;
    }

    updateError = (errorMessage) => {
        this.deviceError = errorMessage
    }

    clear = () => {
        this.deviceImei = '';
        this.deviceModel = '';
        this.deviceMake = '';
        this.deviceRrp = null;
        this.deviceError = null;
        this.deviceActivationDate = '';
        this.dateOfPurchase = null;
        this.skuCode = '';
        this.msisdn = '';
        this.kbxCode = '';
    }
}

decorate(DeviceStore, {
    deviceImei: [persist, observable],
    deviceMake: [persist, observable],
    deviceModel: [persist, observable],
    deviceRrp: [persist("object"), observable],
    deviceError: [persist, observable],
    deviceActivationDate: [persist, observable],
    dateOfPurchase: [persist, observable],
    msisdn: [persist, observable],
    skuCode: [persist, observable],
    kbxCode: [persist, observable],
    updateDeviceActivationDate: action,
    updateDeviceDetail: action,
    updateDeviceInfo: action,
    updateDeviceIMEI: action,
    clear: action,
    updateError: action
})
var deviceStore = new DeviceStore();
export default deviceStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('deviceStore', deviceStore)
}

