import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist';

class OrderStore {

    storeId = '';
    dreiOrderId = '';
    salesRepId = '';
    paymentErrorCode = null;
    paymentErrorMessage = '';


    updateOrderDetail = (orderDetail) => {
        this.storeId = orderDetail.storeId;
        this.dreiOrderId = orderDetail.dreiOrderId;
        this.salesRepId = orderDetail.salesRepId;
    }

    updatePaymentErrorCode(paymentErrorCode){
        this.paymentErrorCode = paymentErrorCode;
    }

    updatePaymentErrorMessage(paymentErrorMessage){
        this.paymentErrorMessage = paymentErrorMessage;
    }

    clear = () => {
        this.storeId = '';
        this.dreiOrderId = '';
        this.salesRepId = '';
        this.paymentErrorCode = null;
        this.paymentErrorMessage = '';
    }
}

decorate(OrderStore, {
    storeId: [persist, observable],
    dreiOrderId: [persist, observable],
    salesRepId: [persist, observable],
    paymentErrorCode: [persist, observable],
    paymentErrorMessage: [persist, observable],
    updateOrderDetail: action,
    updatePaymentErrorCode: action,
    updatePaymentErrorMessage: action,
    clear: action
})
var orderStore = new OrderStore();
export default orderStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('orderStore', orderStore)
}

