import { decorate, observable, action } from "mobx";
import { create, persist } from 'mobx-persist'


class PlanStore {
    planName = '';
    productName = '';
    productId = '';
    planId = '';
    productTenure = '';
    productTenureType = '';
    bandNo = '';
    productPrice = '';
    actualAmountWithoutPromotion = '';
    coverageDesc = '';
    currency = '';
    whatsCovered = '';
    isSubscription = false;
    selectedTenure = null;
    orderNo = '';
    invoiceNumber = '';
    selectedProduct = '';
    actualProductPrice = '';
    discountedProductPrice = '';
    paymentSystemPrice = '';
    productList = null;
    billingDate = null;
    coverageDate = null;
    payment_type_recurring = null;
    promoDefinition = null;
    fulfillmentSystemPrice = null;
    accessToken = null;
    pricingType = null;
    repaireServiceFee = null;
    replacementServiceFee = null;
    displayProductList = null;
    productDescription = null;
    coverageObj = null;
    currencySymbol = null;
    tenureToShow = null;
    paymentDescription = null;
    deviceEndingRange = null;
    merchantId = null;
    mode = 0;


    updateProductInfo = (product) => {
        this.productName = product.productName;
        this.productId = product.productId;
        this.planId = product.plans && product.plans.productId;
        this.deviceRrp = product.deviceRrp;
        this.coverageDesc = product.coverageDesc;
        this.productTenure = product.tenure;
        this.productTenureType = product.tenureType;
        this.productPrice = product.plans && product.plans.retailPriceValueWithTax;
        this.actualAmountWithoutPromotion = product.plans && product.plans.retailPriceValueWithTax * 2;
        this.currency = product.plans && product.plans.currency;
        this.productCoverage = product.productCoverage;
        this.actualProductPrice = product.plans && product.plans.actualProductPrice;
        this.discountedProductPrice = product.plans && product.plans.discountedProductPrice;
        this.paymentSystemPrice = product.plans && product.plans.paymentSystemPrice;
        this.fulfillmentSystemPrice = product.plans && product.plans.fulfillmentSystemPrice
        this.billingDate = product.billingDate;
        this.coverageDate = product.coverageDate;
        this.promoDefinition = product.promoDefinition;
        this.pricingType = product.pricingType;
        this.repaireServiceFee = product.plans && product.plans.repaireServiceFee;
        this.replacementServiceFee = product.plans && product.plans.replacementServiceFee;
        this.coverageObj = product.coverageObj;
        this.productDescription = product.productDescription;
        this.currencySymbol = product.currencySymbol;
        this.tenureToShow = product.tenureToShow;
        this.paymentDescription = product.paymentDescription;
        this.deviceEndingRange = product.plans.deviceEndingRange;
        this.merchantId = product.plans.merchantId;
    }

    updatePaymentType = (isRecurring) => {
        this.payment_type_recurring = isRecurring;
    }

    saveProductArrToStore = (productArr) => {
        this.productList = JSON.parse(JSON.stringify(productArr));
    }

    saveProductDisplayArrToStore = (productArr) => {
        this.displayProductList = JSON.parse(JSON.stringify(productArr));
    }

    saveBandList = (bandList) => {
        this.bandList = bandList;
    }

    updateOrderNo = (orderNo) => {
        this.orderNo = orderNo;
    }

    updateInvoiceNumber = (invoiceNo) => {
        this.invoiceNumber = invoiceNo;
    }

    saveAccessToken = (accessToken) => {
         this.accessToken = accessToken;
    }

    saveMode = (mode) => {
        this.mode = mode;
    }

    clear = () => {
        this.planName = '';
        this.productName = '';
        this.productId = '';
        this.planId = '';
        this.productTenure = '';
        this.productTenureType = '';
        this.bandNo = '';
        this.productPrice = '';
        this.actualAmountWithoutPromotion = '';
        this.coverageDesc = '';
        this.currency = '';
        this.whatsCovered = '';
        this.isSubscription = false;
        this.selectedTenure = null;
        this.orderNo = '';
        this.invoiceNumber = '';
        this.selectedProduct = '';
        this.actualProductPrice = '';
        this.discountedProductPrice = '';
        this.paymentSystemPrice = '';
        this.productList = null;
        this.billingDate = null;
        this.coverageDate = null;
        this.payment_type_recurring = null;
        this.promoDefinition = null;
        this.fulfillmentSystemPrice = null;
        this.accessToken = null;
        this.pricingType = null;
        this.repaireServiceFee = null;
        this.replacementServiceFee = null;
        this.displayProductList = null;
        this.productDescription = null;
        this.coverageObj = null;
        this.currencySymbol = null;
        this.tenureToShow = null;
        this.paymentDescription = null;
        this.deviceEndingRange = null;
        this.merchantId = null;
        this.mode = 0;
    }
}

decorate(PlanStore, {
    planName: [persist, observable],
    productName: [persist, observable],
    productId: [persist, observable],
    planId: [persist, observable],
    productTenure: [persist, observable],
    productTenureType: [persist, observable],
    bandNo: [persist, observable],
    productPrice: [persist, observable],
    actualAmountWithoutPromotion: [persist, observable],
    coverageDesc: [persist, observable],
    currency: [persist, observable],
    isSubscription: [persist, observable],
    whatsCovered: [persist("object"), observable],
    orderNo: [persist, observable],
    selectedTenure: [persist, observable],
    productList: [persist("object"), observable],
    selectedProduct: [persist, observable],
    actualProductPrice: [persist, observable],
    discountedProductPrice: [persist, observable],
    fulfillmentSystemPrice: [persist, observable],
    payment_type_recurring: [persist, observable],
    paymentSystemPrice: [persist, observable],
    promoDefinition: [persist, observable],
    accessToken: [persist, observable],
    pricingType: [persist, observable],
    repaireServiceFee: [persist, observable],
    replacementServiceFee: [persist, observable],
    billingDate: [persist("object"), observable],
    coverageDate: [persist("object"), observable],
    displayProductList: [persist("object"), observable],
    bandList: [persist("object"), observable],
    productDescription: [persist("object"), observable],
    coverageObj: [persist("object"), observable],
    currencySymbol: [persist, observable],
    tenureToShow: [persist, observable],
    deviceEndingRange: [persist, observable],
    merchantId: [persist, observable],
    saveBandList: action,
    saveAccessToken: action,
    updateOrderNo: action,
    updateProductInfo: action,
    updatePaymentType: action,
    saveProductDisplayArrToStore: action,
    clear: action,
    paymentDescription: [persist, observable],
    mode: [persist, observable],
    saveMode: action,
})
var planStore = new PlanStore();
export default planStore;

if (typeof window !== 'undefined') {
    const hydrate = create({
        storage: localStorage,
        jsonify: true
    })
    hydrate('planStore', planStore);
}
